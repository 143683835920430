import * as Sentry from '@sentry/react';
import browserProcessHrtime from 'browser-process-hrtime';
import EventEmitter from 'eventemitter3';
import { createBrowserHistory } from 'history';
import mustache from 'mustache';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import * as WebFont from 'webfontloader';

import { EmotionProvider } from 'src/app/graph/explorerPage/EmotionProvider';
import { initializeReferrer } from 'src/app/onboarding/hooks';
import { RoutedApp } from 'src/app/routedApp/RoutedApp';
import { ThemeProvider } from 'src/components/themeProvider/ThemeProvider';
import { ToastScopeProvider } from 'src/components/toast/Toast';
import { deleteOldBuildTimeLaunchDarkleyCaches } from 'src/hooks/useLocalStorage';
import { migrateQueryParams } from 'src/hooks/useQueryParams';
import { initSentry } from 'src/hooks/useTelemetryInitializer';
import { Analytics } from 'src/lib/analytics';
import { AmplitudeProvider } from 'src/lib/analytics/amplitude/AmplitudeProvider';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import Config from 'src/lib/config';
import { LaunchDarklyProvider } from 'src/lib/launchDarkly';
import { injectRecaptchaEnterpriseScript } from 'src/lib/recaptcha';

import { IsAppBackgroundContextProvider } from './app/appLayout/hooks/useIsAppInBackground';
import { migrateRunHistoryToMax10Items } from './app/graph/explorerPage/hooks/useExplorerState/useOperationHistory';
import {
  migrateEmbeddedExplorerLocalStorageToPerOriginAndPath,
  migrateSandboxLocalStorageToPerReferrer,
} from './app/graph/explorerPage/hooks/usePerGraphIdentifierLocalStorage';
import { IsAppOfflineContextProvider } from './hooks/useIsAppOffline';
import { ApolloClientProvider } from './lib/apollo/ApolloClientProvider';
import { setupServiceWorker } from './serviceWorkerSetup/serviceWorkerRegistration';

const history = createBrowserHistory();
initSentry(history);
Analytics.page(window.location.pathname, document.title);
history.listen((location) => {
  Analytics.page(location.pathname, document.title);
});

// Globally override the mustache escape function to not escape html
mustache.escape = (text) => text;

// We need this for `@graphql-codegen/core` used in at least operation scripts,
// if we remove `@graphql-codegen/core` as a browser based dependency, we can delete browser-process-hrtime
process.hrtime = browserProcessHrtime as NodeJS.HRTime;

// This migrates old links that used schemaTag to use the new value, if we remove this any old saved links will break.
migrateQueryParams();
initializeReferrer();
injectRecaptchaEnterpriseScript();

/**
 * Reduced max run history from 100 to 10 items, this migrates all local storage
 * for run history to max 10 items per variant
 *
 * remove mid 2023?
 */
migrateRunHistoryToMax10Items();

/**
 * Added by Maya :)
 *
 * Delete Dec 2023, added Jan 2023
 *
 * This migrates embedded Sandbox per-graph identifer local storage
 * values from all being stored under a single id to be specific to the parent origin
 */
migrateSandboxLocalStorageToPerReferrer();

/**
 * Added by Maya :)
 *
 * Remove me in Dec 2023, added Jan 2023
 *
 * This migrates embedded Explorer per-graph identifer local storage
 * values from being stored under document.referrer to being stored under
 * document.referrer.origin+document.referrer.path to remove any query params or hashes
 */
migrateEmbeddedExplorerLocalStorageToPerOriginAndPath();

if (Config.gitCommitHash) {
  Object.assign(window, { __GIT_HASH__: Config.gitCommitHash });

  window._fs_ready = () => {
    const url = window.FS?.getCurrentSessionURL?.();
    if (url)
      Sentry.setContext(
        // When renaming this field, also update the field name in the field name in Sentry's Safe Fields config
        // https://sentry.io/settings/apollograph/projects/engine-frontend/security-and-privacy/
        'fullstory',
        { url },
      );
  };
}

deleteOldBuildTimeLaunchDarkleyCaches();

const { segmentKey, amplitudeOptions } = Config.settings;

if (segmentKey) {
  Analytics.initialize(segmentKey);
}

ampli.load(amplitudeOptions);

const eventEmitter = new EventEmitter();

// Forces webfonts to load earlier in the render cycle instead of on-demand.
// see https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/webfont-optimization#optimizing_loading_and_rendering
WebFont.load({
  custom: {
    families: ['Fira Code'],
  },
  google: {
    families: [
      'Source Sans Pro:300,400,600,700',
      'Source Code Pro:400,600,700',
      'Inter:400,600,700',
    ],
  },
  active: () => {
    eventEmitter.emit('webfontsLoaded');
  },
});

const App = () => {
  return (
    <ThemeProvider setGlobalTheme __isInternalWIP className="size-full">
      <ToastScopeProvider name="root">
        <IsAppBackgroundContextProvider>
          <IsAppOfflineContextProvider>
            <RoutedApp history={history} />
          </IsAppOfflineContextProvider>
        </IsAppBackgroundContextProvider>
      </ToastScopeProvider>
    </ThemeProvider>
  );
};

const rootNode = document.getElementById('react-root');
if (!rootNode) throw new Error('missing root dom node');
const container = createRoot(rootNode);
container.render(
  <Router history={history}>
    <CompatRouter>
      <EmotionProvider>
        <ApolloClientProvider>
          <LaunchDarklyProvider>
            <AmplitudeProvider>
              <App />
            </AmplitudeProvider>
          </LaunchDarklyProvider>
        </ApolloClientProvider>
      </EmotionProvider>
    </CompatRouter>
  </Router>,
);

setupServiceWorker();
